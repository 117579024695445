import React from 'react'
import BulletProofHiring from '../components/recruitment_blogs/blogs/bullet_proof_hiring/bullet_proof_hiring'
import SEO from '../components/seo'

const Page = (location) => {
  return (<React.Fragment>
      <SEO title="How to Design the Best Bulletproof Hiring Process in 2021"
        description="A step by step guide to creating a perfect bulletproof hiring system. Tips to ensure bulletproof hiring during the pandemic. Read more"
        url="/bullet-proof-hiring/"
        image="https://blogs.mycareerdreams.com/blogs/bullet_header.webp"
        type="article"
      />
    <BulletProofHiring location={location}/>
  </React.Fragment>
  )
}
export default Page;